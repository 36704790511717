import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=3ef9e619&scoped=true"
import script from "./simple.vue?vue&type=script&lang=js"
export * from "./simple.vue?vue&type=script&lang=js"
import style0 from "./simple.vue?vue&type=style&index=0&id=3ef9e619&prod&lang=scss&module=true"
import style1 from "./simple.vue?vue&type=style&index=1&id=3ef9e619&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3ef9e619",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMainLogo: require('/var/www/html/js/productlisting/components/templates/header/main/Logo.vue').default,AlertsList: require('/var/www/html/js/productlisting/components/common/AlertsList.vue').default,TheLoading: require('/var/www/html/js/productlisting/components/templates/TheLoading.vue').default})
