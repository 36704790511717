import { render, staticRenderFns } from "./Variants.vue?vue&type=template&id=7fd3c164&scoped=true"
import script from "./Variants.vue?vue&type=script&lang=js"
export * from "./Variants.vue?vue&type=script&lang=js"
import style0 from "./Variants.vue?vue&type=style&index=0&id=7fd3c164&prod&module=true&lang=scss"
import style1 from "./Variants.vue?vue&type=style&index=1&id=7fd3c164&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7fd3c164",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default})
