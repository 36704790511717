
/**
 * The minicart's item's bundle's item
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemSetChild :item="item" />
 */
export default {
  name: 'HeaderCartItemSetChild',
  props: {
    /** @prop {Object} [:item="item"] - Bundle item */
    item: {
      type: Object,
      default() { return {}; },
    },
    globalQty: {
      type: Number,
      default: 1,
    },
  },
};
