import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=6a87bf3c"
import script from "./Item.vue?vue&type=script&lang=js"
export * from "./Item.vue?vue&type=script&lang=js"
import style0 from "./Item.vue?vue&type=style&index=0&id=6a87bf3c&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderCartItemGuardian: require('/var/www/html/js/productlisting/components/templates/header/cart/ItemGuardian.vue').default,HeaderCartItemSet: require('/var/www/html/js/productlisting/components/templates/header/cart/ItemSet.vue').default,HeaderCartItemQuantity: require('/var/www/html/js/productlisting/components/templates/header/cart/ItemQuantity.vue').default,HeaderCartItemPrice: require('/var/www/html/js/productlisting/components/templates/header/cart/ItemPrice.vue').default,HeaderCartItemProtection: require('/var/www/html/js/productlisting/components/templates/header/cart/ItemProtection.vue').default})
