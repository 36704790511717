
export default {
  name: 'FooterNavigationMenu',
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
