// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".choosePlan_2faBQ{background:var(--gray-8);border-radius:4px;padding:8px 16px}.row_RnAeV{display:grid;grid-gap:0 8px;align-items:center;gap:0 8px;grid-template-areas:\"icon name\" \"icon button\";grid-template-columns:32px 1fr}.chosenPlan_PgKB\\+ .row_RnAeV{display:flex}.icon_ccJzF{flex-shrink:0;grid-area:icon}.price_mU\\+ln{text-align:right}.viewOrEditBtn_wW3Yt{align-items:center;cursor:pointer;display:flex;font-size:1.4rem;justify-content:flex-end}.viewOrEditBtn__add_ccezE{-webkit-text-decoration:underline;text-decoration:underline}.viewOrEditBtn__add_ccezE:hover,.viewOrEditBtn__edit_WglqA{-webkit-text-decoration:none;text-decoration:none}.viewOrEditBtn__edit_WglqA:hover{-webkit-text-decoration:underline;text-decoration:underline}.viewOrEditBtn__content_H2bmv{color:var(--primary-main)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"choosePlan": "choosePlan_2faBQ",
	"row": "row_RnAeV",
	"chosenPlan": "chosenPlan_PgKB+",
	"icon": "icon_ccJzF",
	"price": "price_mU+ln",
	"viewOrEditBtn": "viewOrEditBtn_wW3Yt",
	"viewOrEditBtn__add": "viewOrEditBtn__add_ccezE",
	"viewOrEditBtn__edit": "viewOrEditBtn__edit_WglqA",
	"viewOrEditBtn__content": "viewOrEditBtn__content_H2bmv"
};
module.exports = ___CSS_LOADER_EXPORT___;
