
/**
 * The parent level of the header's categories menu (desktop version)
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuCategoriesDesktopParentLevel :items="items" />
 */
export default {
  name: 'HeaderMenuCategoriesDesktopParentLevel',
  props: {
    /** @prop {Array} [:items="categories"] - The categories menu's items - gql [TreeMenuItemType]! */
    items: {
      type: Array,
      required: true,
    },
  },
};
