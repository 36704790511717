
import respUtility from '@/mixins/respUtility';
/**
 * The list of the account menu's items
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderAccountMenu :menu-items="menuItems" />
 */
export default {
  name: 'HeaderAccountMenu',
  mixins: [respUtility],
  props: {
    /** @prop {Array} [:menu-items="menuItems"] - The user menu - gql MenuItemWithIconType */
    menuItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    followLink(slug) {
      window.location.href = `${window.location.origin}/${slug}`;
    },
  },
};
