
import { debounce } from '~/mixins/helpers';

export default {
  name: 'FooterCategories',
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      firstItemOnSecondRow: -1,
    };
  },
  mounted() {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.visualViewportResizeHandler);
    }
    this.visualViewportResizeHandler();
  },
  beforeDestroy() {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.visualViewportResizeHandler);
    }
  },
  methods: {
    visualViewportResizeHandler: debounce(function onResize() {
      const categories = [...this.$refs.container.children];
      const { top: firstTop } = categories[0].getBoundingClientRect();

      const firstItemOnSecondRow = categories.findIndex((item) => {
        const { top } = item.getBoundingClientRect();
        return top !== firstTop;
      });

      Object.assign(this, {
        firstItemOnSecondRow,
      });
    }, 100),
  },
};
