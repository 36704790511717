// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

// eslint-disable-next-line import/extensions
import { FRAGMENT_PRODUCT, FRAGMENT_FILTER_BLOCK, FRAGMENT_EMPTY_DATA } from './apolloFragment.js';

export const GET_SEARCH_DATA = gql`
  ${FRAGMENT_PRODUCT}
  ${FRAGMENT_FILTER_BLOCK}
  query getSearchData ($text: String, $request: catalogSearchFilterInput) {
    search {
      catalogSearch(text: $text, request: $request) {
        itemsCount
        perPage
        pages
        page
        minPrice
        maxPrice
        queryText
        autoCorrectQuery
        items {
            ...fragmentCatalogProduct
          ... on catalogSearchProductDynamicItem {
              items {
                  ...fragmentCatalogProduct
              }
          }
        }
        filtersBlock {
          ...fragmentCatalogSearchFilterBlock
        }
      }
    }
  },
`;
export const GET_SEARCH_DATA1 = gql`
  ${FRAGMENT_PRODUCT}
  query getSearchData ($text: String, $request: catalogSearchFilterInput) {
    search {
      catalogSearch(text: $text, request: $request) {
        itemsCount
        perPage
        pages
        page
        minPrice
        maxPrice
        queryText
        autoCorrectQuery
        items {
            ...fragmentCatalogSearchProduct
          ... on catalogSearchProductDynamicItem {
              items {
                  ...fragmentCatalogSearchProduct
              }
          }
        }
      }
    }
  },
`;
export const GET_EMPTY_DATA = gql`
  ${FRAGMENT_EMPTY_DATA}
  query getEmptyData {
    search {
      emptySearchData {
        ...fragmentEmptyData
      }
    }
  },
`;
export const GET_FILTERS_DATA = gql`
  ${FRAGMENT_FILTER_BLOCK}
  query getFiltersData ($text: String, $request: catalogSearchFilterInput) {
    search {
      catalogSearch(text: $text, request: $request) {
        itemsCount
        minPrice
        maxPrice
        filtersBlock {
          ...fragmentCatalogSearchFilterBlock
        }
      }
    }
  },
`;

export const GET_COUNT_DATA = gql`
  query getCount ($text: String, $request: catalogSearchFilterInput) {
    search {
      catalogSearch(text: $text, request: $request) {
        itemsCount
      }
    }
  },
`;

export const GET_MAIN_MENU = gql`
  query getMainMenu {
    menu {
      mainMenu {
        id
        name
        slug
        image {
          id
          src
        }
        submenu {
          id
          name
          slug
          image {
            id
            src
          }
        }
      }
    }
  },
`;

export const GET_BRAND = gql`
  query getBrands {
    menu {
      brands {
        id
        name
        slug
        featured
      }
    }
  },
`;

export const WISH_LIST = gql`
  query wishList ($productIds: [Int]) {
    wishlist {
      productIds(productIds: $productIds)
    }
  },
`;
