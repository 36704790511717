// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categories_ix2ME{margin-top:3px}@media only screen and (min-width:768px){.categories_ix2ME{margin-top:0}}.categories_ix2ME .category_MzdHk{color:var(--gray-0)}.categories_ix2ME .category_MzdHk:hover{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}.inner_m-EOb{--_gap:16px;display:flex;flex-wrap:wrap;gap:12px 0;justify-content:center;margin:0 -16px;margin:0 calc(var(--_gap)*-1)}@media only screen and (min-width:768px){.inner_m-EOb{--_gap:20px}}@media only screen and (min-width:1264px){.inner_m-EOb{--_gap:16px}}.category_MzdHk{font-size:12px;font-style:normal;font-weight:400;line-height:20px;margin:0 var(--_gap);position:relative}.category_MzdHk+.category_MzdHk:before{background-color:var(--gray-4);content:\"\";display:block;height:100%;left:calc(var(--_gap)*-1);opacity:1;pointer-events:none;position:absolute;width:1px}.category_MzdHk.isFirstInRow_b7VCq:before{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categories": "categories_ix2ME",
	"category": "category_MzdHk",
	"inner": "inner_m-EOb",
	"isFirstInRow": "isFirstInRow_b7VCq"
};
module.exports = ___CSS_LOADER_EXPORT___;
