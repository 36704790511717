// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".child_ogrcc{display:grid;grid-template-areas:\"img description\" \"img plan\";grid-template-columns:32px 1fr;grid-gap:8px;gap:8px}.child__protection_eUhPt.chosen{margin-left:4px}.description_BrW8F{align-items:flex-start;display:flex;gap:4px}.img_01uvJ,.qty_\\+uUhr{flex-shrink:0}.text_1rTOK{flex-grow:1;line-height:20px;margin-top:5px}.img_01uvJ{grid-area:img}.qty_\\+uUhr{align-items:center;background:var(--gray-7);border-radius:50%;display:flex;height:28px;justify-content:center;margin:2px;padding:4px 2px;width:28px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"child": "child_ogrcc",
	"child__protection": "child__protection_eUhPt",
	"description": "description_BrW8F",
	"img": "img_01uvJ",
	"qty": "qty_+uUhr",
	"text": "text_1rTOK"
};
module.exports = ___CSS_LOADER_EXPORT___;
