// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_YC-3O{padding-top:3.6rem;text-align:left}@media only screen and (min-width:768px){.container_YC-3O{padding-top:.8rem}}:root .title_bvOTK{color:var(--primary-black);font-size:2.4rem;font-weight:600;line-height:3rem;margin-bottom:1.2rem;margin-left:.8rem;text-align:left}@media only screen and (min-width:768px){:root .title_bvOTK{font-size:3.6rem;line-height:4rem;margin-bottom:2.4rem;margin-top:0}}.backBtn_Ll-WG{margin-bottom:2.4rem}.backBtn_Ll-WG .v-btn__content{line-height:2rem}.description_aT\\+pi{display:grid;grid-gap:8px;gap:8px}.listItem_eEj6D{display:flex;gap:8px}.listItem_eEj6D .vue-icon{flex:none;font-size:1.6rem}.controls_-QBX5{background:var(--gray-9);border-radius:8px;margin-bottom:80px;margin-top:24px;padding:24px 16px}@media only screen and (min-width:768px){.controls_-QBX5{margin-top:16px}}.controls__title_dhCUE{font-size:1.6rem;font-weight:600;line-height:2.2rem}@media only screen and (min-width:768px){.controls__title_dhCUE{font-size:1.8rem;line-height:2.6rem}}.controls_-QBX5 .controls__title_dhCUE{color:var(--primary-black);margin-bottom:16px}.controls_-QBX5 .is_boutique_dvEOL{--primary-main:#000;--secondary-main:#757575;--primary-main-darker:#3f3f3f}.controls_-QBX5 .v-btn{width:100%}@media only screen and (min-width:768px){.controls_-QBX5 .v-btn{max-width:312px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_YC-3O",
	"title": "title_bvOTK",
	"backBtn": "backBtn_Ll-WG",
	"description": "description_aT+pi",
	"listItem": "listItem_eEj6D",
	"controls": "controls_-QBX5",
	"controls__title": "controls__title_dhCUE",
	"is_boutique": "is_boutique_dvEOL"
};
module.exports = ___CSS_LOADER_EXPORT___;
