
import { debounce } from '~/mixins/helpers';
import headerMenuOverlayUtility from '@/mixins/headerMenuOverlayUtility';
import { GET_SEARCH_REDIRECT } from '@/api/apolloCommonQueries';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'HeaderSearchWrapper',
  mixins: [respUtility, headerMenuOverlayUtility],
  data() {
    return {
      searchTerm: this?.$route?.query?.q || '',
      nodeId: 'search_mini_form',
      results: null,
      urlSearchDefault: '/catalogsearch/result',
      onsiteRedirectionUrl: '/onsite-premium-protection-plan',
      guardianRedirectionUrl: '/onsite-premium-protection-plan',
      minNumberOfCharacters: 3,
      fetchSearchParams: null, // { resource, options }
      isSyteaiTooltip: false,
    };
  },
  computed: {
    searchData() {
      const popularProductsDataList = [];
      const keywordSuggestionList = [];
      const { products = [] } = this.results?.response || {};

      products.forEach((item) => {
        const isPopularProducts = item.doctype === 'POPULAR_PRODUCTS';
        const isKeywordSuggestion = item.doctype === 'KEYWORD_SUGGESTION';
        const isInField = item.doctype === 'IN_FIELD';
        const hasNoNumbers = !/\d/.test(item.autosuggest_unstemmed || '');

        if (isPopularProducts) {
          popularProductsDataList.push(item);
        }

        if (
          hasNoNumbers
          && keywordSuggestionList.length < 11
          && (isKeywordSuggestion || isInField)
        ) {
          keywordSuggestionList.push(item);
        }
      });

      return {
        popularProductsDataList,
        keywordSuggestionList,
      };
    },

    hasEnoughCharacters() {
      return this?.searchTerm?.length >= this.minNumberOfCharacters;
    },

    hasResult() {
      return this.results?.response?.products?.length > 0;
    },

    isCurrentQuery() {
      const searchTerm = this.searchTerm.toLowerCase();
      const routQuery = (this.$route.query?.q || '').toLowerCase();
      return (searchTerm !== routQuery);
    },

    isOpenedDropdownList() {
      return this.isCurrentQuery
        && !this.isOpenedImageSearchPopup()
        && this.hasEnoughCharacters
        && this.hasResult
        && this.menu;
    },

    isOnsiteSearchTerm() {
      const searchTerm = this.searchTerm.toLowerCase();
      return /(?:^|\s)on[-_\s]*site(?:\s|$)/.test(searchTerm);
    },

    isGuardianSearchTerm() {
      const searchTerm = this.searchTerm.toLowerCase();
      return searchTerm === 'guardian';
    },
  },
  watch: {
    '$route.query.q': function watchQuery(newQ = '') {
      this.searchTerm = newQ;
    },
  },
  mounted() {
    this.setFetchSearchParams();
  },
  methods: {
    initSyteAi() {
      if (!this.$syteai) {
        return;
      }
      const syteAppReadyHandler = () => {
        this.$refs.camerabtn?.$el?.click();
      };
      window.addEventListener('syteapp_is_ready', syteAppReadyHandler, { once: true });
      this.$syteai.init();
    },

    // eslint-disable-next-line func-names
    inputSearch: debounce(function () {
      this.instantInputSearch();
    }, 450),

    async instantInputSearch() {
      if (!this.hasEnoughCharacters) {
        this.menu = false;
        this.clearResults();
        return;
      }

      if (this.isOpenedImageSearchPopup()) {
        return;
      }

      if (this.isOnsiteSearchTerm || this.isGuardianSearchTerm) {
        this.clearResults();
        return;
      }

      this.results = await this.fetchProposals();

      if (this.hasResult && this.isCurrentQuery) {
        this.menu = true;
        return;
      }

      this.menu = false;
    },

    /*
     * Set basic settings for requesting proposals
     * The query parameter "q" will be added immediately
     * before the request for proposals.
     */
    setFetchSearchParams() {
      const {
        apiBaseUrl,
        apiKey,
        siteKey,
      } = this.$config.unbxd;
      const searchParams = new URLSearchParams({
        version: 'V2',
        'keywordSuggestions.count': 8,
        'promotedSuggestions.count': 8,
        'inFields.count': 8,
        'topQueries.count': 8,
        'popularProducts.count': 8,
      });
      const resource = `${apiBaseUrl}/${apiKey}/${siteKey}/autosuggest?${searchParams}`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'unbxd-user-id': this.extractUnbxdUserIdFromCookie(),
        },
      };

      this.fetchSearchParams = {
        resource,
        options,
      };
    },

    async doSearch() {
      if (!this.hasEnoughCharacters) {
        return;
      }

      const url = await this.getUrlToRedirectTo();

      if (url) {
        window.location.href = url;
        return;
      }

      this.$router.push({
        path: this.urlSearchDefault,
        query: { q: this.searchTerm },
      });
    },

    fetchProposals() {
      const { resource, options } = this.fetchSearchParams;
      const urlObject = new URL(resource);

      urlObject.searchParams.set('q', this.searchTerm || '');

      try {
        return fetch(urlObject.toString(), options).then((data) => data.json());
      } catch (e) {
        console.error(e);
      }

      return null;
    },

    async getUrlToRedirectTo() {
      if (this.isOnsiteSearchTerm) {
        return this.onsiteRedirectionUrl;
      }

      if (this.isGuardianSearchTerm) {
        return this.guardianRedirectionUrl;
      }

      const url = await this.fetchUrlToRedirect(this.searchTerm);

      if (url) {
        return url;
      }

      return null;
    },

    async fetchUrlToRedirect(queryString) {
      if (!queryString) {
        return null;
      }

      try {
        const parameters = [];

        parameters.push({
          name: 'q',
          value: [
            queryString,
          ],
        });

        const { pageMetadata } = await this.$graphql.default
          .request(GET_SEARCH_REDIRECT, { slug: this.urlSearchDefault, parameters }) || {};

        return pageMetadata?.addons?.redirect?.url || null;
      } catch (e) {
        console.error(e);
      }

      return null;
    },

    createKeywordUrl(item) {
      const queryString = encodeURIComponent(item.autosuggest_unstemmed);
      return `${this.urlSearchDefault}?q=${queryString}`;
    },

    async onClickKeyword(e) {
      e.preventDefault();

      const urlInstance = new URL(e.currentTarget.href);
      const searchParamValue = urlInstance.searchParams.get('q');
      const keywordSearchTerm = decodeURIComponent(searchParamValue);
      const url = await this.fetchUrlToRedirect(keywordSearchTerm);

      if (url) {
        /**
         * TODO: Rewrite with this.$router.push()
         * when brand pages will be based-on nuxt.
         */
        window.location.assign(url);

        return;
      }

      this.$router.push({
        path: this.urlSearchDefault,
        query: { q: keywordSearchTerm },
      });
    },

    handleFocusing(e) {
      const isFocus = e.type === 'focus';

      if (isFocus && this.hasResult && this.isCurrentQuery) {
        this.menu = true;
      }
    },

    doClear() {
      this.searchTerm = '';
      this.menu = false;
      this.clearResults();
    },

    clearResults() {
      this.results = null;
    },

    highlightText(item) {
      const regex = new RegExp(`(${this.searchTerm.split(/\s+/).join('|')})`, 'gi');
      return item.replace(regex, '<span class="iMark">$&</span>');
    },

    isOpenedImageSearchPopup() {
      /* Everything is right here! */
      const firstPartOfId = 'syte-camera-tour-screen-container';
      const secondPartOfId = 'syte-camera-tour-screen-container-1stopbedroom';
      return !!document.getElementById(`${firstPartOfId} ${secondPartOfId}`);
    },

    extractUnbxdUserIdFromCookie(stringLikeCookie = document.cookie) {
      const re = /unbxd\.userId=(.*?);/;
      const [, uid] = re.exec(stringLikeCookie) || [];
      return uid || null;
    },
  },
};
