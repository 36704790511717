
/**
 * The minicart's item's quantity
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemQuantity :quantity="qty" @setQty="setQty" @remove="onRemove" />
 * @events ['set-qty', 'remove']
 */
export default {
  name: 'HeaderCartItemQuantity',
  props: {
    /** @prop {Number} [:quantity="5"] - The product's quantity */
    quantity: {
      type: Number,
      required: true,
    },
    clearance: {
      type: Object,
    },
  },
  methods: {
    /** Emit 'remove' event */
    onRemove() {
      this.$emit('remove');
    },
  },
};
