/**
 * Author: Alex Sheplyakov <alexdev@wiserbrand.com>
 * 1StopBedrooms.com, All rights reserved.
 */

class TaskManager {
  constructor() {
    this.taskQueue = [];
    this.queueActive = false;
    this._delayFunction = false;
  }
  delayFunction(callback) {
    if(!this._delayFunction) {
      if('requestIdleCallback' in window) {
        this._delayFunction = window.requestIdleCallback;
      } else {
        this._delayFunction = window.requestAnimationFrame;
      }
    }
    this._delayFunction.call(window, callback);
  }

  append(callback) {
    this.taskQueue.push(callback);
    if(!this.queueActive) {
      this.queueActive = true;
      this.delayFunction(() => {
        this.runner();
      });
    }
  }

  runner() {
    const callback = this.taskQueue.pop();
    callback();

    if(this.taskQueue.length) {
      this.delayFunction(() => {
        this.runner()
      });
    } else {
      this.queueActive = false;
    }
  }
}


export default ({ }, inject) => {
  const taskManager = new TaskManager();
  inject('taskManager', taskManager);
};

// vim: ft=javascript:sw=2:ts=2:fdm=marker:
