
import respUtility from '~/mixins/respUtility';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('product');

export default {
  name: 'HeaderAdditionalPhone',
  mixins: [respUtility],
  computed: {
    ...mapGetters({
      totalPrice: 'totalPricePDP',
    }),
    phone() {
      const phoneData = this.$store.state.cart.phone || {};
      return {
        iconName: 'IconHeaderPhone-24x24',
        ...phoneData,
      };
    },
  },
  watch: {
    totalPrice: {
      handler(newPrice) {
        const tiers = this.$store.state?.cart?.phonePriceTier;
        const subtotal = this.$store.state?.cart?.cart?.subtotal || 0;
        const sumPriceCart = newPrice + subtotal;
        if (tiers) {
          const currentPriceTier = this.$store.state?.product?.product?.general?.productType === 'Mattress'
            ? tiers?.mattress[0]
            : tiers?.general?.find((tier) => sumPriceCart >= tier.from && sumPriceCart <= tier.to);
          if (currentPriceTier) {
            this.$store.commit(
              'cart/setPhone',
              {
                phone: currentPriceTier.phone,
                slug: currentPriceTier.slug,
              },
            );
          }
        }
      },
    },
  },
};
