// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const FRAGMENT_MENU_DATA = gql`
    fragment fragmentMenuData on NavigationCategory {
        dropdown_title
        icon
        id
        level
        name
        slug
        url
    }
`;

export const FRAGMENT_BRAND_MENU_DATA = gql`
    fragment fragmentBrandMenuData on NavigationBrand {
        icon
        name
        url
        slug
    }
`;

export const FRAGMENT_META_DATA = gql`
    fragment fragmentMetaData on PageMetadata {
        addons {
            breadcrumbs {
                name
                slug
                url
                originalName
            }
            head {
                title
                description
                keywords
                robots
                content_type
                isBoutique
            }
            metaOg {
                title
                url
                type
                description
                image
                image_alt
            }
            ... on CPMeta {
                filters: popularFilters {
                    url
                    label
                    internalRoute
                }
                categories: relatedCategories {
                    url
                    label
                    internalRoute
                }
                seoData: seoBlock {
                    content
                    placement
                }
                articles {
                    url
                    image
                    header: title
                    shortDescription
                }
            }
            ... on BPMeta {
                description
            }
        }
    }
`;

export const FRAGMENT_CART_ITEM_DATA = gql`
    fragment fragmentCartItemData on MinicartItem {
        product {
            productUrl
            productType
            price
            pplanSkip
            brand {
                id
                name
            }
            id
            img {
                classes
                style
                thumbnail
            }
            preview {
                classes
                style
                thumbnail
            }
            name
            originalPrice
            clearanceQty
            webId
        }
        itemId
        price
        protectionPlan {
            id
            name
            price
            qty
        }
        qty
        regularPrice
        showMsrp
        discount
        coupon
    }
`;
export const FRAGMENT_CART_DATA = gql`
    ${FRAGMENT_CART_ITEM_DATA}
    fragment fragmentCartData on MinicartType {
        cartNumber
        summaryCount
        items {
            ...fragmentCartItemData
            ... on BundleCartItem {
              childItems {
                ...fragmentCartItemData
              }
              childItemsCount
              pplanInclude
            }
        }
        subtotal
        couponData {
            code
            timeRemains
            timerText
            secondText
            detailHoverLabel
            detailHoverText
        }
    }
`;

export const FRAGMENT_CUSTOMER = gql`
    fragment fragmentCustomer on CustomerType {
        id
        firstname
        lastname
        email
        created_at
        updated_at
        links {
            addressBookLink
            editLink
            preferencesLink
        }
    }
`;
