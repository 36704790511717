

import respUtility from '@/mixins/respUtility';
/**
 * The content of the header's brand menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuBrandsDesktopLuxury :brands="brands" />
 */
export default {
  name: 'HeaderMenuBrandsDesktopLuxury',
  mixins: [respUtility],
  props: {
    /** @prop {Array} [:brands="featuredBrands"] - The featured brands menu data - gql [BrandsMenuType]! */
    brands: {
      type: Array,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
};
