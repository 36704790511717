
/**
 * The main layout
 * @category [Layouts]
 * @nuxt-listeners [
 *   'change-global-loading', 'change-global-scrolling', 'change-global-overlay'
 * ]
 */
import { GET_META_DATA } from '@/api/apolloCommonQueries';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';

export default {
  name: 'simpleLayout',
  mixins: [
    headerMenuOverlayUtility,
  ],
  data() {
    return {
      overlay: false,
      hasOverlayWithDelay: false,
      loadingPopup: false,
      pageScroll: true,
      redirect: null,
    };
  },
  head() {
    return {
      title: this.headData?.title || '1StopBedrooms',
      meta: [
        {
          name: 'robots',
          content: this.headData?.robots || 'index, follow',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.headData?.description ? this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.ogData?.title || '1StopBedrooms',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.ogData?.url || 'https://www.1stopbedrooms.com/',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.ogData?.description ?  this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogData?.image || 'https://www.1stopbedrooms.com/media/1stopbedrooms-logo.jpg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.ogData?.image_alt || '1StopBedrooms',
        },
      ],
    };
  },
  async fetch() {
    await this.getMetadata();
  },
  computed: {
    messages() {
      return this.$store.state.messages;
    },
    headData() {
      return this.$store.state.metaData?.addons?.head;
    },
    ogData() {
      return this.$store.state.metaData?.addons?.metaOg;
    },
  },
  watch: {
    overlay(val) {
      /*
       * The "hasOverlayWithDelay" property changes to false with a delay.
       * This is to remove the "hasOverlay" class with a delay.
       * This prevents clicking on site elements under the <v-overlay>.
       */

      if (val) {
        this.hasOverlayWithDelay = val;
      } else {
        new Promise((res) => {
          setTimeout(res, 500);
        }).then(() => {
          this.hasOverlayWithDelay = val;
        });
      }
    },
    '$route.path': function watchRoute() {
      this.$nextTick(() => {
        this.getMetadata();
        this.$nuxt.$emit('change-global-loading', false);
      });
    },
  },
  created() {
    this.$nuxt.$on('change-global-loading', (status) => {
      this.loadingPopup = status;
    });
    this.$nuxt.$on('change-global-scrolling', (status) => {
      this.pageScroll = status;
    });
    this.$nuxt.$on('change-global-overlay', (status) => {
      this.overlay = status;
    });
  },
  mounted() {
    this.$taskManager.append(() => {
      this.pffpTest;
    });
  },
  beforeDestroy() {
    this.$nuxt.$off('change-global-loading');
    this.$nuxt.$off('change-global-scrolling');
    this.$nuxt.$off('change-global-overlay');
  },
  methods: {
    onClose(msg) {
      if (msg && msg.id) {
        this.$store.commit('removeMessage', msg.id);
      }
    },
    async getMetadata() {
      this.redirect = null;
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(GET_META_DATA, {
        slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
        parameters: parameters.length ? parameters : null,
      });
      const redirect = result.pageMetadata?.addons?.redirect?.url;
      if (redirect) {
        this.redirect = redirect[0] === '/' ? redirect : `/${redirect}`;
        window.location.href = this.redirect;
        return;
      }
      this.$store.commit('setMetaData', result.pageMetadata || {});
    },
    pffpTest() {
      /*
       * Detection of the uncanny relationship between
       * position fixed and filter property.
       */
      if (process.browser) {
        const root = document.querySelector('html');
        const head = document.querySelector('head');
        const body = document.querySelector('body');
        const style = document.createElement('style');
        const css = `
          /* Detection of the uncanny relationship between position fixed and filter property. */
          #t-pffp,
          #t-pffp div {
            position: fixed;
            top: 10px;
            left: 0px;
            filter: blur(0px);
            height: 1px;
            width: 1px;
            opacity: 0;
            pointer-events: none;
          }
        `;
        const html = '<div id="t-pffp"><div></div></div>';
        const template = document.createElement('template');
        template.innerHTML = html;
        const container = template.content.querySelector('#t-pffp');
        const child = container.querySelector('div');

        style.appendChild(document.createTextNode(css.replaceAll(/[ ]+/g, ' ')));
        head.appendChild(style);
        body.appendChild(template.content);

        const rectOfContainer = container.getBoundingClientRect();
        const rectOfChild = child.getBoundingClientRect();
        const different = parseInt(rectOfContainer.top, 10) - parseInt(rectOfChild.top, 10);
        const variableValue = (different === 0) ? 0 : 1;

        /*
         * The value of the --t-pffp variable equal to 0 means
         * that the child element is at the same distance
         * from the viewport as the container
         */
        root.style.setProperty('--t-pffp', variableValue);
      }
    },
  },
};
