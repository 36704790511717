// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_Nizvq{align-items:center;display:flex;font-size:1.6rem;font-weight:600;line-height:2.2rem}.button_Nizvq:hover{-webkit-text-decoration:underline;text-decoration:underline}.button_Nizvq:focus-visible{outline:var(--accessibility-outline)}.button_Nizvq.is_active_doL\\+Y svg{transform:rotate(180deg)}.headerSubmenuOverlay_6T-7P{bottom:0;left:0;position:absolute;right:0;top:0}.categoriesSubmenu_etm5L{border:none!important;border-radius:0!important;bottom:0;left:0!important;overflow:hidden;width:100%}.headerSubmenuWrap_BlTgd{height:calc(100% - 16px);margin-top:16px;position:relative}.headerSubmenuWrap_BlTgd:before{border:8px solid transparent;border-bottom:8px solid var(--primary-white);content:\"\";left:396px;position:absolute;top:-16px;z-index:20}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_Nizvq",
	"is_active": "is_active_doL+Y",
	"headerSubmenuOverlay": "headerSubmenuOverlay_6T-7P",
	"categoriesSubmenu": "categoriesSubmenu_etm5L",
	"headerSubmenuWrap": "headerSubmenuWrap_BlTgd"
};
module.exports = ___CSS_LOADER_EXPORT___;
