
import {
  UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
} from '@/api/apolloHeaderFooterMutations';
import { GET_CART_DATA } from '@/api/apolloCommonQueries';
import { mapMutations } from 'vuex';
import protectionPlanMixin from '@/mixins/protectionPlanMixin';

/**
 * The minicart's item
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItem v-bind="item" @open-popup="$emit('open-popup', item.product.id )" />
 * @events ['open-popup', 'start-changing', 'end-changing']
 */
export default {
  name: 'HeaderCartItem',
  mixins: [protectionPlanMixin],
  props: {
    /** @prop {Object} [:product="item.product"] - The product's data */
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    /** @prop {Number} [:quantity="5"] - The product's quantity */
    qty: {
      type: Number,
      required: true,
    },
    /** @prop {Number} [:price="500"] - The product's price */
    price: {
      type: Number,
      default: 0,
    },
    /** @prop {Number} [:regular-price="500"] - The product's price */
    regularPrice: {
      type: Number,
      default: 0,
    },
    /** @prop {Boolean} [:show-msrp="true"] - Show product's msrp */
    showMsrp: {
      type: Boolean,
      default: true,
    },
    /** @prop {Object} [:protection-plan="item.protectionPlan"] - The product's onsite protection data */
    protectionPlan: {
      type: Object,
    },
    /** @prop {Array} [:selections="item.selections"] - The bundle's selected items */
    selections: {
      type: Array,
    },
    /** @prop {Number} [:child-items-count="5"] -
     * The bundle's selected items' count */
    childItemsCount: {
      type: Number,
      default: 0,
    },
    /** @prop {Object} [:child-items="childItems"] - Bundle items */
    childItems: {
      type: Array,
      default() {
        return [];
      },
    },
    /** @prop {Object} [:pplan-include="pplanInclude"] - Bundle items */
    pplanInclude: {
      type: Array,
      default() {
        return [];
      },
    },
    /** @prop {Number} [:item-index="5"] -
     * It is used for the select's id and as an argument for open the onsite popup function */
    itemId: {
      type: [Number, String],
      default: 0,
    },
    /** @prop {Boolean} [new-item] - To display new status */
    newItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    /** V-model for the input connects with store */
    qty1: {
      get() {
        return this.qty;
      },
      set(newValue) {
        let qty = 1;
        if (Number.isInteger(newValue)) {
          const max = Math.max(this.qty, 10);
          if (newValue >= max) {
            qty = max;
          } else if (newValue > 1) {
            qty = newValue;
          }
        }
        if (qty === this.qty) {
          return;
        }
        const handler = async () => {
          try {
            await this.$graphql.default.request(UPDATE_CART_ITEM, { item: { id: this.itemId, qty } });
          } catch (e) {
            console.error(e);
          }
          this.setNewItems([this.itemId]);
        };
        this.onChange(handler);
      },
    },
    protectionPlanPrice() {
      if (!this?.itemProtectionPlan?.price) {
        return 0;
      }

      if (this.childItemsCount && this?.protectionPlan?.id) {
        const totalPrice = this.childItems.reduce((sum, childItem) => {
          const pplans = this.getItemPlans(childItem);
          const { price } = pplans.find((pplan) => pplan.id === this.protectionPlan.id);

          return sum + price * childItem.qty;
        }, 0);

        return totalPrice;
      }

      return this.itemProtectionPlan.price;
    },
    itemProtectionPlan() {
      if (this.product.productType !== 'Mattress') {
        return this.protectionPlan;
      }
      if (Array.isArray(this.childItems) && this.childItems[0]) {
        return this.childItems[0].protectionPlan;
      }
      return this.protectionPlan;
    },
    /** Onsite protection possibility */
    isProtectionPlan() {
      if (this.protectionPlan) {
        return true;
      }
      if (!this.childItemsCount) {
        return !this.product.pplanSkip;
      }
      if (this.product.productType === 'Mattress') {
        return true;
      }
      return this.childItems.some(
        (i) => !i.protectionPlan && !i.product.pplanSkip,
      );
    },
    protectionItems() {
      const result = [];
      const mainItem = {
        itemId: this.itemId,
        product: this.product,
        protectionPlan: this.protectionPlan,
        price: this.price,
        qty: this.qty,
        pplanInclude: this.pplanInclude,
        pplanSubitems: [],
      };
      if (this.childItemsCount) {
        this.childItems.forEach((i) => {
          const subitem = {
            itemId: i.itemId,
            product: i.product,
            protectionPlan: i.protectionPlan,
            price: i.price,
            qty: i.qty,
          };
          if (this.pplanInclude && this.pplanInclude.includes(i.itemId)) {
            mainItem.pplanSubitems.push(subitem);
            return;
          }
          if (i.product.pplanSkip) {
            return;
          }
          result.push(subitem);
        });
        if (mainItem.pplanSubitems.length) {
          result.unshift(mainItem);
        }
      } else if (!this.product.pplanSkip) {
        result.push(mainItem);
      }
      return result.map((i) => {
        let itemPlans;
        if (i.pplanSubitems && i.pplanSubitems.length) {
          itemPlans = i.pplanSubitems.reduce((arr, subitem) => {
            const plans = this.getItemPlans(subitem);
            if (subitem.qty > 1) {
              // eslint-disable-next-line no-param-reassign,no-return-assign
              plans.forEach((plan) => plan.price *= subitem.qty);
            }
            if (arr.length) {
              // eslint-disable-next-line no-param-reassign,no-return-assign
              arr.forEach((plan, index) => plan.price += plans[index].price);
            } else {
              arr.push(...plans);
            }
            return arr;
          }, []);
        } else {
          itemPlans = this.getItemPlans(i);
        }
        return {
          ...i,
          product: {
            ...(i.product || {}),
            img: i?.product?.preview,
          },
          plans: itemPlans,
        };
      });
    },
    clearance() {
      const price = this.product?.price || 0;
      const clearanceQty = this.product?.clearanceQty || 0;
      const originalPrice = this.product?.originalPrice || 0;
      const finalPrice = price;
      if (clearanceQty > 0 && clearanceQty < 10
      && originalPrice > finalPrice) {
        return {
          qty: clearanceQty,
          originalPrice,
          price: finalPrice,
        };
      }
      return null;
    },
  },
  methods: {
    ...mapMutations({
      /** without arguments, it set an empty array as new items */
      setNewItems: 'cart/setNewItems',
    }),
    /** Remove item */
    onRemove() {
      const handler = async () => {
        try {
          const { cart } = await this.$graphql.default.request(REMOVE_CART_ITEM, { itemId: this.itemId }) || {};
          const success = cart?.remove?.item?.success;
          if (success) {
            window.dataLayer = window.dataLayer || [];
            // eslint-disable-next-line no-undef
            dataLayer.push(
              {
                event: 'CartRemoved',
                CartRemovedPayload: {
                  pid: this.product.webId,
                  qty: `${this.qty}`,
                  variantId: 'null',
                  price: `${this.product.price}`,
                },
              },
            );
          }
        } catch (e) {
          console.error(e);
        }
      };
      this.onChange(handler);
    },
    /** Make a qgl mutation with this item's changing  */
    async onChange(handler) {
      if (!(typeof handler === 'function')) {
        return;
      }
      this.$emit('start-change');
      this.loading = true;
      await handler();
      try {
        const { cart } = await this.$graphql.default
          .request(GET_CART_DATA, { subtotal: this.currentCart?.subtotal || 0, webId: null }) || {};
        this.$store.commit('cart/setCart', cart?.minicart);
        this.$store.commit('cart/setPhone', cart?.phone?.phoneByTotal);
        this.$emit('end-change');
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.$emit('end-change');
      }
    },
    /** On the tabbing mode focus on the quantity input */
    showQuantity() {
      const handler = function goToInput() {
        if (this.$refs?.item) {
          const input = this.$refs.item.querySelector('input');
          if (input) {
            input.focus();
          }
        }
      };

      this.$implementOnTabbingMode(handler, true);
    },
    /** Change qty */
    setQty(newQty) {
      this.qty1 = newQty;
    },
  },
};
