
import strUtility from '@/mixins/strUtility';

/**
 * The minicart's item's onsite block
 * @category [Header, Cart, Onsite]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemProtection :protection-plan="item.protectionPlan" @open-popup="$emit('open-popup' )" />
 * @events ['open-popup']
 */
export default {
  name: 'HeaderCartItemProtection',
  mixins: [strUtility],
  props: {
    /** @prop {Object} [:protection-plan="item.protectionPlan"] - The product's onsite protection data */
    protectionPlan: {
      type: Object,
    },
    protectionPlanPrice: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
    },
  },
};
