// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wishListBtn_ipNhP{align-items:center;display:flex;grid-gap:.8rem;color:var(--primary-white);gap:.8rem}.wishListBtn_ipNhP:hover{-webkit-text-decoration:underline;text-decoration:underline}.icon_6LTEW{flex:0 0 auto;height:2.4rem;width:2.4rem}@media only screen and (min-width:1264px){.icon_6LTEW{height:3.2rem;width:3.2rem}}.icon_6LTEW path{stroke:#fff}.label_OfM0f{font-size:1.4rem;font-weight:400;line-height:2rem;width:6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wishListBtn": "wishListBtn_ipNhP",
	"icon": "icon_6LTEW",
	"label": "label_OfM0f"
};
module.exports = ___CSS_LOADER_EXPORT___;
