import Vue from 'vue';
/* eslint-disable import/no-extraneous-dependencies */
import { VPagination } from 'vuetify/lib';

const BasePagination = {
  name: 'BasePagination',
  extends: VPagination,
  computed: {
    items() {
      const totalVisible = parseInt(this.totalVisible, 10);

      if (totalVisible === 0 || Number.isNaN(this.length) || this.length > Number.MAX_SAFE_INTEGER) {
        return [];
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, this.maxButtons) || this.length,
        this.length,
      );

      if (this.length <= maxLength) {
        return this.range(1, this.length);
      }
      const even = totalVisible % 2 === 0;
      const middle = even ? totalVisible / 2 : Math.floor(totalVisible / 2);
      const left = even ? middle : middle + 1;
      const right = this.length - middle;
      const start = 1;
      if (left - this.value >= 0) {
        return [...this.range(start, Math.max(1, totalVisible - 2)), '...', this.length];
      } if (this.value - right >= (even ? 1 : 0)) {
        const rangeLength = totalVisible - 3;
        const rangeStart = this.length - rangeLength;
        return [start, '...', ...this.range(rangeStart, this.length)];
      }
      const rangeLength = Math.max(1, totalVisible - 4);
      const rangeStart = rangeLength === 1 ? this.value : this.value - Math.ceil(rangeLength / 2) + start;
      return [start, '...', ...this.range(rangeStart, rangeStart + rangeLength - 1), '...', this.length];
    },
  },
};

Vue.component('BasePagination', BasePagination);
