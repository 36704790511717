
import respUtility from '~/mixins/respUtility';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
import { GET_INITIAL_HEADER } from '@/api/apolloCommonQueries';
import { TRACK_CJ_EVENT } from '@/api/apolloCustomerMutations';

export default {
  name: 'TheHeader',
  mixins: [respUtility, headerMenuOverlayUtility],
  props: {
    headerData: {
      type: Object,
    },
  },
  async fetch() {
    const { protectionPlan, cart, customer } = await this.$graphql.default
      .request(GET_INITIAL_HEADER, { subtotal: 0, webId: null }) || {};
    this.$store.commit('setProtectionData', protectionPlan || {});
    this.$store.commit('cart/setCart', cart?.minicart);
    this.$store.commit('setCustomer', customer);
    this.$store.commit('cart/setPhone', cart?.phone?.phoneByTotal);
    this.$store.commit('cart/setPhonePriceTier', cart?.phone?.phonePriceTier);
  },
  fetchOnServer: false,
  data: () => ({
    /** The header's styles and content depends on the main menu's status on mobile */
    isMobileMenuOpen: false,
    isMenuPermanent: false,
    sessionStorageNameBrandPage: 'is-brand-page',
  }),
  watch: {
    isRespLgAndUp() {
      this.isMobileMenuOpen = false;
    },
    isBoutiquePage() {
      this.$nextTick(this.boutiqueHandler);
    },
  },
  created() {
    this.$nuxt.$on('change-global-permanent', (status) => {
      this.isMenuPermanent = status;
    });
  },
  mounted() {
    this.$taskManager.append(() => {
      this.boutiqueHandler();
      this.handleCJEvent();
    });
  },
  beforeDestroy() {
    this.$nuxt.$off('change-global-permanent');
  },
  computed: {
    isBoutiquePage() {
      if (process.server) {
        return !!this.$store.state.metaData?.addons?.head?.isBoutique;
      }
      let isBoutiqueState = false;
      if (window.serverState && window.serverState.theme) {
        const metaData = this.$store.state?.metaData || {};
        isBoutiqueState = window?.serverState?.theme === 'boutique';
        delete window.serverState.theme;
        metaData.addons = metaData.addons || {};
        metaData.addons.head = metaData.addons.head || {};
        metaData.addons.head.isBoutique = isBoutiqueState;
        this.$store.commit('setMetaData', metaData);
      }
      return !!this.$store.state.metaData?.addons?.head?.isBoutique || isBoutiqueState;
    },
  },
  methods: {
    toggleMobileMenu(newValue) {
      this.isMobileMenuOpen = newValue;
    },
    boutiqueHandler() {
      if (sessionStorage.getItem(this.sessionStorageNameBrandPage) && this.$store.state?.pageData?.globalType !== 'BRAND') {
        sessionStorage.removeItem(this.sessionStorageNameBrandPage);
      }
    },
    handleCJEvent() {
      if (process.client) {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const cookie = {};
        document.cookie.split(';').forEach((el) => {
          const [key, value] = el.split('=');
          cookie[key.trim()] = value;
        });
        const cjeventParam = Array.from(params.entries()).find(([key]) => key.toLowerCase() === 'cjevent');
        if (cjeventParam) {
          const [, param] = cjeventParam;
          const cjeventExpiredDate = new Date();
          cjeventExpiredDate.setDate(cjeventExpiredDate.getDate() + 30);
          const oldVal = cookie.cjevent;
          if (oldVal !== param) {
            document.cookie = `cjevent=${param};expires=${cjeventExpiredDate.toUTCString()};path=/`;
            (async () => {
              try {
                const data = await this.$graphql.default
                  .request(TRACK_CJ_EVENT, { cjevent: param }) || {};
                if (data?.cjeventMutations?.trackEvent?.success !== true) {
                  console.error('CJ Event Tracking is failed');
                }
              } catch (error) {
                console.error('CJ Event Tracking is failed');
              }
            })();
          }
        }
      }
    },
  },
};
