import { GET_REDIRECT } from '@/api/apolloCommonQueries';

const NUXT_PAGES = ['PLP', 'SEARCH_RESULTS', 'PDP', 'BRAND', 'COLLECTION', 'CLEARANCE', 'PROMOPAGE'];
const LISTING_PAGES = ['PLP', 'SEARCH_RESULTS', 'PDP', 'BRAND', 'COLLECTION', 'CLEARANCE', 'PROMOPAGE'];

export default ({ app }) => {
  // Every time the route changes (fired on initialization too)
  app.router.beforeEach(async (to, from, next) => {
    let pageType = '';
    if (from.path !== to.path) {
      const { pageMetadata } = await app.$graphql.default.request(
        GET_REDIRECT,
        { slug: to.path },
      );
      const { url, type } = pageMetadata?.addons?.redirect || {};
      if (url) {
        const redirect = url[0] === '/' ? url : `/${url}`;
        const isInternal = NUXT_PAGES.includes(type);
        if (isInternal) {
          return next({ path: redirect });
        }
      }
      pageType = pageMetadata?.pageType;
      app.store.commit('setPageDataReplaceType', pageType);
    }
    if (!LISTING_PAGES.includes(pageType || app.store.state.pageData.globalType)) {
      return next();
    }
    const defaultParams = app.store.getters['listing/defaultParams'];
    const updatedQuery = { ...to.query };
    const sortVariants = ['RELEVANCE', 'PRICE_FROM_LOW', 'PRICE_FROM_HIGHT'];
    const perPageVariants = ['PER_PAGE_36', 'PER_PAGE_48', 'PER_PAGE_72'];
    if (to.query['per-page']) {
      const perPage = updatedQuery['per-page'].toUpperCase();
      if (perPage === defaultParams.perPage || !perPageVariants.includes(perPage)) {
        delete updatedQuery['per-page'];
      }
    }
    if (to.query['sort-by']) {
      const sort = updatedQuery['sort-by'].toUpperCase();
      if (sort === defaultParams.sortBy || !sortVariants.includes(sort)) {
        delete updatedQuery['sort-by'];
      }
    }
    if (to.query.page) {
      if (Number(updatedQuery.page) === defaultParams.page) {
        delete updatedQuery.page;
      }
    }
    if (updatedQuery.collection_type
      && !(updatedQuery.brand && updatedQuery.brand.length) && to.name !== 'brand') {
      delete updatedQuery.collection_type;
    }
    if (JSON.stringify(updatedQuery) !== JSON.stringify(to.query)) {
      return next({ ...to, query: updatedQuery });
    }
    return next();
  });
  app.router.afterEach(async (to, from) => {
    if (app.store.state.pageData.type !== to.name) {
      app.store.commit('setPageDataType', to.name);
    }
    if (from.name && app.store.state.pageData.initial) {
      app.store.commit('setPageDataInitial', false);
    }
    if (app.store.state.pageData.replaceType) {
      app.store.commit('setPageDataGlobalType', app.store.state.pageData.replaceType);
      app.store.commit('setPageDataReplaceType', '');
    }
    if (!LISTING_PAGES.includes(app.store.state.pageData.globalType)) {
      return;
    }
    const copy = { ...to.query };
    const data = {};
    if (to.query['per-page']) {
      data.perPage = to.query['per-page'].toUpperCase();
      delete copy['per-page'];
    }
    if (to.query['sort-by']) {
      data.sortBy = to.query['sort-by'].toUpperCase();
      delete copy['sort-by'];
    }
    if (to.query.page) {
      data.page = Number(to.query.page);
      delete copy.page;
    }
    if (to.name === 'search') {
      data.text = copy.q || '';
      delete copy.q;
      delete copy.s;
    }
    if (copy.facet) {
      try {
        data.facet = JSON.parse(to.query.facet);
      } catch (e) {
        console.error('route error: ', e);
      }
    } else {
      delete copy.id;
      delete copy.clearance;
      const facet = { ...copy };
      Object.keys(facet).forEach((key) => {
        if (!(typeof facet[key] === 'string' && facet[key].length)) {
          delete facet[key];
          return;
        }
        facet[key] = facet[key]?.split('$,$') || '';
      });
      if (Object.keys(facet).length) {
        data.facet = facet;
      }
    }
    if (JSON.stringify(data) !== JSON.stringify(app.store.state.searchParams)) {
      app.store.commit('listing/setSearchParams', data);
    }
    if (data.sortBy !== app.store.state.listing.currentSort) {
      app.store.commit('listing/setCurrentSort', data.sortBy);
    }
  });
};
