const CONSTANTS = {
  // onestopbedroom/js/productlisting/components/templates/product/info/configurableItem/Option.vue
  OFF_COUPON: 'Off Coupon',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/grouping/Item.vue
  SIZE: 'Size',
  BED_SIZE: 'Bed Size',
  MATRESS_SIZE: 'mattress_size',
  BED_SIZE_MEASURE: 'bed_size_measure',
  COMFORT_LEVEL: 'comfort_level',
  LAYOUT: 'layout',
  BUNDLE: 'BUNDLE',
  MATRESS: 'Mattress',
  SECTIONAL: 'Sectional',
  COLOR: 'color',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/AddToCart.vue
  AT_REGULAR_PRICE: 'atRegularPrice',
  FUNCTION: 'function',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/DeliveryContent.vue
  WHITE_GLOVE: 'white_glove',
  FREE_IN_HOME: 'free_in_home',
  UPS_FEDEX: 'ups_fedex',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/IncludeToggle.vue
  PRODUCT: 'product',
  FOUNDATION: 'foundation',
  RELATED: 'related',
  ACCESSORY: 'accessory',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/Information.vue
  DESCRIPTION: 'description',
  DIMENSIONS: 'dimensions',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/ShopMore.vue
  VIEWSET: 'VIEWSET',
  COLLECTION: 'COLLECTION',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/general/Tags/Banner.vue
  ONLY_2_LEFT_AT_THIS_PRICE: 'Only 2 left at this price',
  JUST_A_FEW_LEFT_ORDER_SOON: 'Just a few left. Order soon.',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/general/Tags/Primary.vue
  SEASONAL_SALE: 'Seasonal Sale',
  DAILY_DEAL: 'Daily Deal',
  CLEARANCE: 'Clearance',
  OSB_CHOICE: '1SB Choice',
  OSB_EXCLUSIVE: '1SB Exclusive',
  STAFF_PICK: 'Staff Pick',
  ON_SALE: 'On Sale',
  MADE_IN_USA: 'Made in USA',
  BEST_SELLER: 'Best Seller',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/general/Tags/Secondary.vue
  LABOR_DAY_DEAL: 'Labor Day Deal',
  LIMITED_TIME_DEAL: 'Limited Time Deal',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/general/Tags/Tertiary.vue
  LOWEST_IN_30_DAYS: 'Lowest price in 30 days',
  ON_SALE_UNTIL: 'On Sale Until',
  // onestopbedroom/js/productlisting/components/templates/product/info/recommendedMattress/Details.vue
  SERTA: 'Serta',
  // onestopbedroom/js/productlisting/components/templates/product/info/recommendedMattress/Option.vue
  CALIFORNIA_KING: 'California King',
  CAL_KING: 'Cal King',
  // onestopbedroom/js/productlisting/components/templates/product/info/ShippingAndReturn.vue
  FREIGHT: 'freight',
  EXPRESS: 'express',
  FREE_SHIPPING: 'FREE Shipping',
  // onestopbedroom/js/productlisting/components/templates/product/info/details/additional/Grouping.vue
  SIMPLE: 'SIMPLE',
  TEXT: 'TEXT',
  BED: 'bed',
  // onestopbedroom/js/productlisting/components/templates/product/info/reviews/item/Action.vue
  BTN_SECONDARY: 'btn-secondary',
  BTN_QUATERNARY: 'btn-quaternary',
  PERSON: 'person',
  PEOPLE: 'people',
  REMOVE: 'REMOVE',
  ADD: 'ADD',
  // onestopbedroom/js/productlisting/components/templates/product/info/reviews/Full.vue
  REVIEW: 'review',
  REVIEWS: 'reviews',
  // onestopbedroom/js/productlisting/components/templates/product/info/reviews/Items.vue
  PX: 'px',
  AUTO: 'auto',
  SECTIONAL_LOWER: 'sectional',
  SOFA: 'sofa',
  // onestopbedroom/js/productlisting/components/templates/product/info/Reviews.vue
  BY_CUSTOMER_PHOTOS: 'by_customer_photos',
  BY_MOST_RELEVANT: 'by_most_relevant',
  // onestopbedroom/js/productlisting/components/templates/product/info/gallery/GallerySliders.vue
  PRODUCT_IMAGE_TYPE: 'ProductImageType',
  CAROUSEL_VIDEO_TYPE: 'CarouselVideoType',
  // onestopbedroom/js/productlisting/components/templates/product/info/gallery/GallerySliderSlideMain.vue
  CAROUSEL_BTN_3D_TYPE: 'CarouselBtn3DType',
  GOOGLE_BOT: 'Googlebot',
  PRODUCT_IMAGES: 'productImages',
  CUSTOMER_IMAGES: 'customerImages',
  VISIBILITY_NOT_VISIBLE: 'VISIBILITY_NOT_VISIBLE',
  VISIBILITY_IN_CATALOG: 'VISIBILITY_IN_CATALOG',
  VISIBILITY_IN_SEARCH: 'VISIBILITY_IN_SEARCH',
  VISIBILITY_BOTH: 'VISIBILITY_BOTH',
  VISIBILITY_CLONING_BRAND: 'VISIBILITY_CLONING_BRAND',
  WIDTH: 'width',
  DEPTH: 'depth',
};

export default (_, inject) => {
  // Inject the constants into the app
  inject('pdpConstants', CONSTANTS);
};
