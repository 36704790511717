
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
/**
 * The parent level's item of the header's categories menu (mobile version)
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuMobileParentLevelItem :menu-item="item" :menu-index="index" :main-menu-id="nodeId" />
 */
export default {
  name: 'HeaderMenuMobileParentLevelItem',
  mixins: [headerMenuOverlayUtility],
  props: {
    /** @prop {Object} [:menu-item="item"] - The categories menu's item - gql TreeMenuItemType! */
    menuItem: {
      type: Object,
      required: true,
    },
    /** @prop {Number} [:menu-index="5"] - The categories menu's item's index - for id */
    menuIndex: {
      type: Number,
      default: 0,
    },
    /** @prop {String} [main-menu-id="header-categories"] - The categories parent menu menu's id */
    mainMenuId: {
      type: String,
      default: 'header-menu-mobile',
    },
  },
  data() {
    return {
      /** change ref name for the onFocusOut (headerMenuOverlayUtility) function */
      nodeId: `${this.mainMenuId}-submenu-${this.menuIndex}`,
      /** we don't need to change overlay status on open/close a submenu, just on open/close the main menu */
      changeOverlay: false,
      closeOnClick: false,
    };
  },
  watch: {
    menu(newValue) {
      this.$emit('change-status-child', newValue);
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};

