// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item_3P8nj{display:flex;flex-direction:column;gap:16px;position:relative}.item_3P8nj:before{background-color:var(--tertiary-main-1);border-radius:0 4px 4px 0;bottom:0;content:\"\";display:block;left:-24px;opacity:0;position:absolute;top:0;transition:opacity 1s;width:8px;z-index:1}.item__loading_u70BD{pointer-events:none}.item__loading_u70BD>*{opacity:.5}.item__protection_RHGIU.chosen{margin-left:16px}.item_3P8nj.newItem_\\+Lbhg:before{opacity:1}.mainInfo_r4o4n{display:flex;gap:16px}.imgWrapper_g6L8N{flex-shrink:0;height:64px;width:64px}.imgWrapper_g6L8N img{height:100%;-o-object-fit:contain;object-fit:contain;width:100%}.description_KdWql{display:flex;flex-direction:column;flex-grow:1;gap:4px}.description_KdWql a:hover{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}.name_yyBt5{font-size:14px;font-weight:400;line-height:20px}.name_yyBt5.name_yyBt5{color:var(--primary-black)}.brand_8YvQL{font-size:12px;font-weight:400;line-height:20px}.price_0CRTe{align-items:center;display:flex;justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item_3P8nj",
	"item__loading": "item__loading_u70BD",
	"item__protection": "item__protection_RHGIU",
	"newItem": "newItem_+Lbhg",
	"mainInfo": "mainInfo_r4o4n",
	"imgWrapper": "imgWrapper_g6L8N",
	"description": "description_KdWql",
	"name": "name_yyBt5",
	"brand": "brand_8YvQL",
	"price": "price_0CRTe"
};
module.exports = ___CSS_LOADER_EXPORT___;
