
import respUtility from '@/mixins/respUtility';
/**
 * The component with contacts' buttons in the footer
 * @category footer
 * @component
 * @example <caption>FooterContacts usage (see code)</caption>
 * <FooterContacts :contacts="footer.contacts" />
 */
export default {
  name: 'FooterContacts',
  mixins: [respUtility],
  props: {
    contacts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isTelShown: false,
    };
  },
  computed: {
    callListeners() {
      const listeners = {
        click: () => {
          this.showPhone();
          // this.dispatchClickOnCallBtn();
        },
      };

      return this.isTelShown ? {} : listeners;
    },
    phone() {
      return this.$store.state.cart.phone || {};
    },
    email() {
      const item = this.contacts.find((i) => i.name === 'Email');
      return item?.data?.slug || 'https://support.1stopbedrooms.com/hc/en-us/requests/new';
    },
  },
  /** Get contacts' data from the backend */
  methods: {
    /** Show phone after the first click on the link and add tel href to the link */
    showPhone() {
      this.isTelShown = true;
    },

    /** Simulates a user pressing the Call button. */
    dispatchClickOnCallBtn() {
      this.$nextTick(() => {
        this.$refs.callBtn.$el.click();
      });
    },
  },
};
