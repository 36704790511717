
import respUtility from '~/mixins/respUtility';
/**
 * The main footer component
 * @category footer
 * @component
 * @example <caption>TheFooter usage (see code)</caption>
 * <TheFooter />
 */
export default {
  name: 'TheFooter',
  mixins: [respUtility],
  props: {
    footerData: {
      type: Object,
    },
  },
  data: () => ({
    footerLoading: 0,
    isFrozen: false,
    isTopButton: false,
  }),
  computed: {
    menu() {
      return {};
    },
  },
  watch: {
    isRespMdAndUp() {
      this.changeTopButtonProps();
    },
  },
  mounted() {
    this.$taskManager.append(() => {
      this.changeTopButtonProps();
    });
    window.addEventListener('resize', this.changeTopButtonProps);
    window.addEventListener('scroll', this.changeTopButtonProps);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeTopButtonProps);
    window.removeEventListener('scroll', this.changeTopButtonProps);
  },
  methods: {
    setFooterTop() {
      this.footerTop = this.$refs.footer && this.$refs.footer.offsetTop ? this.$refs.footer.offsetTop : 0;
    },
    /** Displays the scroll button or not depends on the scroll position */
    changeTopButtonProps() {
        const { scrollY, innerHeight } = window;
        this.isTopButton = scrollY > innerHeight * 0.25;
    },
    getViewportHeight() {
      if (window.innerHeight) {
        return window.innerHeight;
      }
      return document?.body?.offsetHeight || 0;
    },
  },
};
