// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".count_rWhLY,.header_FlGy\\+{align-items:center;display:flex;gap:8px}.header__title_sKH3t{font-weight:600}.count_rWhLY{color:var(--primary-main)}.count_rWhLY.is_boutique_DtxI4{--primary-main:#000}.count_rWhLY path{fill:var(--primary-main)}.count_rWhLY svg{transition:.3s cubic-bezier(.25,.8,.5,1),visibility 0s}.count__active_wQo6N svg{transform:rotate(180deg)}.list_VfJ7-{display:flex;flex-direction:column;margin-top:16px}.item__plan_bL8GM{margin-bottom:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": "count_rWhLY",
	"header": "header_FlGy+",
	"header__title": "header__title_sKH3t",
	"is_boutique": "is_boutique_DtxI4",
	"count__active": "count__active_wQo6N",
	"list": "list_VfJ7-",
	"item__plan": "item__plan_bL8GM"
};
module.exports = ___CSS_LOADER_EXPORT___;
