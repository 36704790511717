export class onDemand {
  // src - the full url to load
  // waitForPageLoad - still wait for the page load event on first hit
  constructor(src, waitForPageLoad = true) {
    this.isLoaded = false;
    this.isLoading = false;
    this.callbacks = [];
    this.src = src;
    this.waitForPageLoad = waitForPageLoad;
  }

  // eslint-disable-next-line consistent-return
  load(callback = () => {}) {
    if (this.isLoaded) return callback();

    this.callbacks.push(callback);

    if (!this.isLoading) {
      this.isLoading = true;
      // eslint-disable-next-line no-underscore-dangle
      if (!this.waitForPageLoad || document.readyState === 'complete') this._loadScript();
      // eslint-disable-next-line no-underscore-dangle
      else window.addEventListener('load', () => this._loadScript());
    }
  }

  _loadScript() {
    const script = document.createElement('script');
    script.src = this.src;
    // eslint-disable-next-line no-underscore-dangle
    script.onload = () => this._invokeCallbacks();
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  _invokeCallbacks() {
    this.isLoaded = true;
    this.callbacks.forEach((callback) => callback());
  }
}

export default onDemand;
